module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/src/locales","languages":["vi","en"],"defaultLanguage":"vi","redirect":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"VSJ","short_name":"VSJ","start_url":"/","icon":"/opt/build/repo/src/images/logo.svg","display":"standalone","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"95f5b940d4f52da0afdf2db29ad24434"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
